.menu-btn {
    display: none;
}

.nav {
    position: fixed;
    width: 100vw;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .09);
    z-index: 1;
}
.mobile-closed {
    display: none;
}

.nav-logo-header {
    color: #19191B;
    font-weight: 700;
    font-size: 2rem;
    transition: .15s ease-out;
    letter-spacing: 0.75rem;
}

.nav-logo-header:hover {
    color: #f7df1e;
    cursor: pointer;
    transition: .15s ease-in;
    transform: translateY(-0.25rem);
}

.nav-list {
    display: flex;
    align-items: center;
    gap: 3rem;
    font-size: 1.8rem;
}

.nav-menu-item {
    color: #19191B;
    transition: .15s ease-out;
    font-weight: 700;
}

.nav-menu-item:hover {
    color: #f7df1e;
    transition: .15s ease-in;
    cursor: pointer;
    transform: translateY(-0.15rem);
}

@media screen and (max-width: 1020px) {

    .nav {
        padding: 2.25rem
    }

    .nav-logo-header {
        font-size: 1.8rem;
    }

    .menu-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        cursor: pointer;
        transition: all .5s ease-in-out;
        z-index: 1000;
    }

    .mobile-nav{
        align-items: center;
        background-color: #ffffff;
        display: flex;
        height: 100vh;
        position: fixed;
        justify-content: center;
        text-align: center;
        top: 7rem;
        transition: all .3s ease-in-out;
        width: 100vw;
        z-index: 100;

    }

    .mobile-closed {
        left: -100% !important;
    }

    .mobile-open {
        display: flex;
        left: 0% !important;
    }

    .nav-list {
        display: none;
    }

    .mobile-nav-list {

        display: flex;
        flex-direction: column;
        font-size: 2.3rem;
        gap: 4rem;
        list-style: none;
        margin-bottom: 15rem;
    }

    .mobile-nav-menu-item {
        color: #fff;
    }

    .menu-btn__burger {
        width: 28px;
        height: 2px;
        background: #19191b;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }
    .menu-btn__burger::before,
    .menu-btn__burger::after {
        content: '';
        position: absolute;
        width: 28px;
        height: 2px;
        background: #19191b;
        border-radius: 5px;
        transition: all .5s ease-in-out;
    }
    .menu-btn__burger::before {
        transform: translateY(-10px);
    }
    .menu-btn__burger::after {
        transform: translateY(10px);
    }

    .menu-btn.open .menu-btn__burger {
        transform: translateX(-50px);
        background: transparent;
        box-shadow: none;
    }
    .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg) translate(35px, -35px);
    }
    .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg) translate(35px, 35px);
    }

}


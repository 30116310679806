.h1-hero-heading.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: 1s;
    transition-delay: 0.2s;
}

.h1-hero-heading.show {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
}

.p-hero-text.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: 1s;
}

.p-hero-text.show {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 0.5s;
}

.contacts.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(20%);
    transition: 1s;
}

.contacts.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    transition-delay: 1s;
}

.dev-picture.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(20%);
    transition: 1s;
}

.dev-picture.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.tech-stack-name.hidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-name.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition-delay: 0.8s;
}

.tech-stack-item.hidden:nth-child(1) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(2) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(3) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(4) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(5) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(6) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.tech-stack-item.hidden:nth-child(7) {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}


.tech-stack-item.show:nth-child(1) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 1s;
}

.tech-stack-item.show:nth-child(2) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 1.2s;
}

.tech-stack-item.show:nth-child(3) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 1.4s;
}

.tech-stack-item.show:nth-child(4) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 1.6s;
}

.tech-stack-item.show:nth-child(5) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 1.8s;
}

.tech-stack-item.show:nth-child(6) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 2s;
}

.tech-stack-item.show:nth-child(7) {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 2.2s;
}


.home {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

.home-container {
    margin: 0 auto 12rem auto;
    max-width : 107rem;
    padding: 0 4rem;
}

.dev-picture {
    border-radius: 9rem;
    height: auto;
    width: 430px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.h1-hero-heading {
    color: #19191b;
    font-size: 8rem;
    font-weight: 700;
    padding-bottom: 1.7rem;
    font-family: 'Poppins', sans-serif;
}

.hero-content {
    margin-top: 25rem;
    display: flex;
    align-items: center;
    gap: 6rem;
}

.hero-text {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
}

.p-hero-text {
    font-size: 1.24em;
    font-family: "Poppins", sans-serif;
}

.contacts {
    margin-top: 2rem;
}

.linkedin {
    fill: #0077b5;
    font-size: 3rem;
    transition: ease-out 0.1s;
}

.linkedin:hover {
    cursor: pointer;
    transform: translateY(-0.35rem);
    transition: ease-in 0.1s;
}

.github {
    fill: #333;
    font-size: 3rem;
    transition: ease-out 0.1s;
}

.github:hover {
    cursor: pointer;
    transform: translateY(-0.35rem);
    transition: ease-in 0.1s;
}

.contact-svg {
    padding-right: 1.5rem;
}

.tech-stack {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-top: 10rem;
}

.tech-stack-name {
    font-family: 'Poppins', sans-serif;
    font-size: 2.3rem;
    font-weight: 700;
    border-right: #19191b 0.3rem solid;
    padding-right: 1.5rem;
}

.tech-stack-list {
    display: flex;
    gap: 2rem;
}

.tech-stack-item {
    animation-name: bounceUpandDown;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    transition: ease-out 0.3s;
}

.tech-stack-item {
    img {
        width: 40px;
    }
}

.tech-stack-item:nth-child(1) {
    animation-delay: 3s;
}

.tech-stack-item:nth-child(2) {
    animation-delay: 3.5s;
}

.tech-stack-item:nth-child(3) {
    animation-delay: 4s;
}

.tech-stack-item:nth-child(4) {
    animation-delay: 4.5s;
}

.tech-stack-item:nth-child(5) {
    animation-delay: 5s;
}

.tech-stack-item:nth-child(6) {
    animation-delay: 5.5s;
}

.tech-stack-item:nth-child(7) {
    animation-delay: 6s;
}

.tech-stack-item:hover {
    cursor: pointer;
    transition: ease-in 0.3s;
}

@keyframes bounceUpandDown {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(-8px)
    }

    100% {
        transform: translateY(0px)
    }
}

@media screen and (max-width: 1020px) {
    .hero-content {
        display: flex;
        flex-direction: column-reverse;
        gap: 3.5rem;
        margin-top: 15rem;
    }

    .hero-text {
        align-items: center;
    }

    .dev-picture {
        width: 430px;
    }

    .h1-hero-heading {
        text-align: center;
        font-size: 6rem;
    }

    .p-hero-text {
        text-align: center;
        font-size: 1.8rem;
    }

    .contact-svg {
        font-size: 3rem;
    }

    .tech-stack {
        text-align: center;
        flex-direction: column;
        gap: 3rem;
        margin-top: 8.5rem;
    }

    .tech-stack-name {
        font-size: 2.4rem;
        padding-right: 0;
        border-bottom: #19191b 0.3rem solid;
        border-right: none;
    }

    .tech-stack-item {
        img {
            height: auto;
            width: 36px;
        }
    }

    @keyframes bounceUpandDown {
        0% {
            transform: translateY(0px)
        }

        50% {
            transform: translateY(-7px)
        }

        100% {
            transform: translateY(0px)
        }
    }
}

@media screen and (max-width: 525px) {

    .home-container {
        margin-bottom: 8rem;
    }

    .hero-content {
        margin-top: 13rem;
    }

    .dev-picture {
        width: 280px;
    }

    .h1-hero-heading {
        font-size: 4.6rem;
    }

    .p-hero-text {
        font-size: 1.8rem;
    }

    .contact-svg {
        font-size: 2.6rem;
    }

    .tech-stack-item {
        img {
            width: 26px;
        }
    }

    .tech-stack {
        margin-top: 5rem;
    }

    @keyframes bounceUpandDown {
        0% {
            transform: translateY(0px)
        }

        50% {
            transform: translateY(-6px)
        }

        100% {
            transform: translateY(0px)
        }
    }

}

@media screen and (max-width: 380px) {

    .home-container {
        padding: 0;
    }

    .hero-content {
        margin-top: 12.75rem;
        gap: 2.5rem;
    }

    .dev-picture {
        width: 220px;
    }

    .h1-hero-heading {
        font-size: 3.7rem;
        padding-bottom: 1.5rem;
    }

    .p-hero-text {
        font-size: 1.4rem;
        padding: 0 2rem 0 2rem;
    }

    .contact-svg {
        font-size: 2.4rem;
    }

    .tech-stack {
        gap: 2.2rem;
    }

    .tech-stack-name {
        font-size: 1.8rem;
    }

    .tech-stack-item {
        img {
            width: 22px;
        }
    }

    @keyframes bounceUpandDown {
        0% {
            transform: translateY(0px)
        }

        50% {
            transform: translateY(-5px)
        }

        100% {
            transform: translateY(0px)
        }
    }
}
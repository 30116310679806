.about-title.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(-20%);
    transition: all 1s;
}

.about-title.show {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
}

.img-container.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}

.img-container.show {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 0.3s;
}

.biography-h2.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(20%);
    transition: all 1s;
}

.biography-h2.show {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 0.3s;
}

.biography-p.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(20%);
    transition: all 1s;
}

.biography-p.show {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
    transition-delay: 0.5s;
}

.about-section {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.about-container {
    margin: 15rem auto;
    padding: 0rem 4rem;
    width: 107rem;
}

.about-content {
    display: flex;
    gap: 8rem;
    margin-top: 6rem;
    align-items: center;
}

.about-title {
    color: #19191b;
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    border-left:  #0073CF 0.3rem solid;
    padding-left: 1rem;
}

.education-entry {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.programmer-svg {
    height: 310px;
    width: 500px;
    border-radius: 1.7rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.biography-h2 {
    color: #19191b;
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    padding-bottom: 6rem;
}

.biography-h2-span {
    font-size: 2.4rem;
    font-weight: bold;
    color: #19513a;
}

.biography-entry {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.8rem;
}

.biography-p {
    font-family: "Poppins", sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
}

@media screen and (max-height: 1184px) {
    .home {
        height: auto;
    }
}

@media screen and (max-width: 1020px) {

    .about-title {
        margin-bottom: 6rem;
    }

    .about-container {
        width: fit-content;
    }

    .about-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 0;
        gap: 0;
    }

    .img-container {
        padding-bottom: 3rem;
    }

    .programmer-svg {
        height: 270px;
        width: 490px;
    }

    .biography-h2 {
        padding-bottom: 2.4rem;
    }

    .biography-p {
        width: 42rem;
    }
}

@media screen and (max-width: 700px) {

    .programmer-svg {
        height: 240px;
        width: 440px;
    }

}

@media screen and (max-width: 525px) {

    .about-container {
        padding-right: 0;
        padding-left: 0;
        margin-top: 8rem;
        margin-bottom: 8rem;
    }

    .about-title {
        font-size: 2.4rem;
        margin-bottom: 4rem;
    }

   .programmer-svg {
       height: 200px;
       width: 345px;
   }

    .biography-p {
        font-size: 1.6rem;
        width: 30rem;
    }

}

@media screen and (max-width: 440px) {

    .programmer-svg {
        height: 190px;
        width: 320px;
    }

}

@media screen and (max-width: 380px) {

    .about-container {
        padding: 0;
    }

    .programmer-svg {
        height: 160px;
        width: 260px;
    }

    .biography-h2 {
        font-size: 1.9rem;
    }

    .biography-h2-span {
        font-size: 1.9rem;
    }

    .biography-p {
        font-size: 1.46rem;
        width: 23rem;
    }

}
.contact-header.hidden {
    opacity: 0;
    transform: translateY(-20%);
    filter: blur(5px);
    transition: 1s;
}

.contact-header.show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

.location-information.hidden {
    opacity: 0;
    transform: translateX(-20%);
    filter: blur(5px);
    transition: 1s;
}

.location-information.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.email-container.hidden {
    opacity: 0;
    transform: translateX(-20%);
    filter: blur(5px);
    transition: 1s;
}

.email-container.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition-delay: 0.2s;
}

.contact-section {
    display: flex;
}

.contact-container {
    padding: 0rem 4rem;
    margin: 12rem auto;
    width: 107rem;
}

.contact-information-container {
    display: flex;
    flex-direction: column;
}

.contact-header {
    font-family: "Poppins", sans-serif;
    color: #19191b;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4.5rem;
    border-left:  #0073CF 0.3rem solid;
    padding-left: 1rem;
    width: fit-content;
    margin-bottom: 6rem;
}

.contact-center {
    display: flex;
    gap: 5rem;
}

.info-header {
    font-size: 2.5rem;
    font-family: 'Poppins', sans-serif;
    color: #19191b;
    margin-bottom: 0.8rem;
}

.info-des {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
    color: #616161;
}

@media screen and (max-width: 1020px) {
    .contact-container {
        width: fit-content;
    }

}

@media screen and (max-width: 700px) {

    .info-header {
        font-size: 2.35rem;
    }

}

@media screen and (max-width: 525px) {

    .contact-container {
        padding-right: 0;
        padding-left: 0;
        margin-top: 8rem;
        margin-bottom: 8rem;
    }

    .contact-header {
        font-size: 2.4rem;
        margin-bottom: 4rem;
    }

    .info-header {
        font-size: 1.8rem;
    }

    .info-des {
        font-size: 1.6rem;
    }

}

@media screen and (max-width: 440px) {
    .contact-header {
        font-size: 2.4rem;
    }

    .info-header {
        font-size: 1.6rem;
    }

    .info-des {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 380px) {

    .contact-container {
        padding: 0;
    }

    .contact-header {
        font-size: 2.4rem;
    }

    .info-header {
        font-size: 1.2rem;
    }

    .info-des {
        font-size: 1.1rem;
    }

}